<template>
    <v-container>
        <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
    </v-container>
</template>

<script>
import { db } from '@/firebase'

export default {
    props: {
        redirectId: {
            type: String,
            required: true,
        },
        query: {
            type: Object,
        },
    },
    data() {
        return {
            errorMessage: '',
        }
    },
    mounted() {
        // Fetch the redirect associated with the redirectId provided.
        db.collection('redirect')
            .doc(this.redirectId)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    // If the redirect exists, replace the current route with the redirects target.
                    const target = doc.data().target
                    console.debug(`Replacing path with ${target}`)
                    this.$router.replace(target)
                } else {
                    // If it doesn't exist, populate the error message.
                    this.errorMessage = `Invalid redirect ${this.redirectId}`
                }
            })
            .catch((err) => {
                this.errorMessage = err.message
            })
    },
}
</script>

<style scoped></style>
